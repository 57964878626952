import T from "ant-design-vue/es/table/Table";
import {showMsg} from "@/utils";
const props = Object.assign({}, T.props, {
  // 表格标题
  title: String,
  // 是否显示表头
  showTitle: Boolean,
  // 滚动
  scroll: {
    type: Object,
    default: () => {}
  }
});
export default {
  name: "ECTable",
  props,
  data() {
    return {
    }
  },
  methods: {
    // 批量删除
    batchRemove() {
      if (!this.rowSelection.selectedRowKeys.length) {
        showMsg({ flag: 1, msg: this.$t("button.batchDeleteSelected") });
        return
      }
      this.$emit('batchRemove')
    },
    change (pagination, filters, sorter, options) {
      this.$emit("change", {
        ...pagination,
        filters,
        sorter,
        options,
      });
    }
  },
  render() {
    const newProps = {};
    const slotName = ["footer", "footerCustom", "tableHeaderRight"];
    Object.keys(props).forEach((k) => {
      this[k] && (newProps[k] = this[k]);
      return newProps[k];
    });
    // 分页
    const pagination = {
      showTotal: (total) => `${this.$t('total')} ${total} ${this.$t('item')}`,
      showSizeChanger: true,
    };
    // 表格
    const table = (
      <a-table
        scroll={ this.scroll }
        size={this.size}
        pagination={this.pagination === false ? false : Object.assign(pagination, this.pagination)}
        columns={this.columns}
        {...{ newProps, scopedSlots: { ...this.$scopedSlots } }}
        rowSelection={(this.rowSelection && Object.keys(this.rowSelection).length) ? this.rowSelection : null}
        data-source={this.dataSource}
        bordered={this.bordered}
        loading={this.loading}
        onChange={this.change}>
        {/* 渲染slot内容 */}
        {
          Object.keys(this.$slots).map((name) => (
            !slotName.includes(name)
              ? <template slot={name}>{this.$slots[name]}</template>
              : null
          ))
        }
        {/* 自定义表格底部批量操作 */}
        {
          this.rowSelection && Object.keys(this.rowSelection).length > 0 && this.dataSource.length
            ? (
              <template slot="footer">
                <div className="table-batch-select" style="display: flex; align-items: center; justify-content: space-between">
                  <span className="batch-select-left" style="margin-right: 10px">
                    {this.$t("selected")}
                    <span className="light">
                      {this.rowSelection.selectedRowKeys?.length}
                    </span>
                    {this.$t("item")}
                  </span>
                  {
                    !Object.keys(this.$slots).includes("footerCustom")
                      ? (
                        <a-button type="danger" ghost onClick={this.batchRemove}>
                          {this.$t("button.batchDel")}
                        </a-button>
                      )
                      : this.$slots["footerCustom"]
                  }
                </div>
              </template>
            )
            : null
        }
      </a-table>
    )

    // 判断是否有数据
    const isHasData = this.dataSource.length > 0
    const element = (
      <div class="table-card ec-card ec-table-wrapper">
        {this.showTitle === false ? null : (
          <div className="ec-card-header table-card-header" style="display:flex;align-items:center;justify-content:space-between;padding:10px;border-bottom:1px solid #e8e8e8">
            <div className="ec-card-title table-card-title" style="font-size: 16px;font-weight: bold">
              {
                this.title || (
                  isHasData ?
                    this.$t("backCheck.dcHasAddTitle") :
                    this.$t("backCheck.dcNotAddTitle")
                )
              }
            </div>
            {/* 表格头部右侧插槽 */}
            <slot name="tableHeaderRight" className="table-card-btn">
              <div>{this.$slots["tableHeaderRight"]}</div>
            </slot>
          </div>
        )}
        {table}
      </div>
    )
    return element
  }
}
