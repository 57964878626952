<template>
  <div class="benefits-base">
    <template v-if="edit">
      <a-form-model
        ref="formRef"
        class="base-form"
        :model="form"
        :rules="rules"
        :label-col="calcFormItemLayout([8, 10], [14, 12]).labelCol"
        :wrapper-col="calcFormItemLayout([8, 10], [14, 12]).wrapperCol"
        :colon="false"
      >
        <a-form-model-item
          class="benefits-base-form-item"
          :label="$t('welfarePlat.index.baseSet.name')"
          prop="name"
        >
          <a-input v-model="form.name" size="large" />
        </a-form-model-item>
        <a-form-model-item
          class="benefits-base-form-item upload"
          :label="$t('welfarePlat.index.baseSet.logo')"
          prop="logo"
        >
          <ec-form-upload
            :format="['image/jpeg', 'image/jpg', 'image/png']"
            :fileList.sync="fileList"
            :size-max="10485760"
            :number-max="1"
            @change="onFileUploadChange"
          />
          <div class="base-tip upload">
            <div class="base-tip-icon icon-tip" />
            <span class="tip base-tip-text">
              {{ $t('welfarePlat.index.baseSet.logoTips') }}
            </span>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="benefits-base-form-item"
          :label="$t('welfarePlat.index.baseSet.moneyName')"
          prop="moneyName"
        >
          <a-input v-model="form.moneyName" size="large" />
          <div class="base-tip">
            <div class="base-tip-icon icon-tip" />
            <span class="tip base-tip-text">
              {{ $t('welfarePlat.index.baseSet.moneyNameTips', { length: 3 }) }}
            </span>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="benefits-base-form-item"
          :label="$t('welfarePlat.index.baseSet.tool')"
          prop="tool"
        >
          <a-switch v-model="form.tool" />
          <div class="base-tip">
            <div class="base-tip-icon icon-tip" />
            <span class="tip base-tip-text">
                {{ $t('welfarePlat.index.baseSet.toolTips') }}
              </span>
          </div>
        </a-form-model-item>
      </a-form-model>
    </template>
    <template v-else>
      <div class="benefits-base-show">
        <div class="benefits-base-show-item">
          <label class="benefits-base-show-label">
            {{ $t('welfarePlat.index.baseSet.name') }}
          </label>
          <div class="benefits-base-show-value">
            <span>{{ platformConf.name }}</span>
            <a-button
              type="link"
              class="benefits-base-show-btn"
              @click="onBaseEdit('name')"
              v-if="userInfo.master"
            >
              <a-icon type="edit" />
            </a-button>
          </div>
        </div>
        <div class="benefits-base-show-item">
          <label class="benefits-base-show-label">
            {{ $t('welfarePlat.index.baseSet.logo') }}
          </label>
          <div class="benefits-base-show-value">
            <div
              class="benefits-base-logo"
              :style="{ backgroundImage: `url(${platformConf.logo})` }"
            />
          </div>
        </div>
        <div class="benefits-base-show-item">
          <label class="benefits-base-show-label">
            {{ $t('welfarePlat.index.baseSet.moneyName') }}
          </label>
          <div class="benefits-base-show-value">
            <span>{{ platformConf.moneyName }}</span>
            <a-button
              type="link"
              class="benefits-base-show-btn"
              @click="onBaseEdit('moneyName')"
              v-if="userInfo.master"
            >
              <a-icon type="edit" />
            </a-button>
          </div>
        </div>
        <div class="benefits-base-show-item">
          <label class="benefits-base-show-label">
            {{ $t('welfarePlat.index.baseSet.tool') }}
          </label>
          <div class="benefits-base-show-value">
            <span>{{ platformConf.tool ? $t('button.yes'): $t('button.no') }}</span>
            <a-button
              type="link"
              class="benefits-base-show-btn"
              @click="onBaseEdit('tool')"
              v-if="userInfo.master"
            >
              <a-icon type="edit" />
            </a-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EcFormUpload from "@/components/EcFormUpload.vue";
import { FETCH_CODE } from "@/config";
import { mapState } from "vuex";
import { langMixin } from "@/mixins";

export default {
  name: "BenefitsBase",
  components: { EcFormUpload },
  props: {
    edit: Boolean,
    data: Object,
  },
  mixins: [langMixin],
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
      userInfo: state => state.user.info,
    }),
  },
  data() {
    return {
      form: {
        name: "",
        logo: "",
        moneyName: "",
        tool: false,
      },
      rules: {
        name: [
          { required: true, message: this.$t("welfarePlat.index.baseSet.nameRequired") },
          { max: 15, message: this.$t("welfarePlat.index.baseSet.nameMax", { length: 15 }) },
        ],
        logo: [
          { required: true, message: this.$t("welfarePlat.index.baseSet.logoRequired") },
        ],
        moneyName: [
          { required: true, message: this.$t("welfarePlat.index.baseSet.moneyNameRequired") },
          { max: 3, message: this.$t("welfarePlat.index.baseSet.moneyNameMax", { length: 3 }) },
        ],
      },
      fileList: [],
    };
  },
  methods: {
    // 平台信息编辑
    onBaseEdit (field) {
      this.$emit("on-edit", { field });
    },
    // 图片上传响应
    onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.logo = data;
    },
    // 保存校验
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
  },
  mounted() {
    this.form = {
      ...this.$store.state.user.platformConf,
    };
    if (this.form.logo) {
      this.fileList = [{
        uid: "-1",
        name: "logo.png",
        status: "done",
        url: this.form.logo,
      }];
    }
  },
};
</script>

<style lang="less" scoped>
.base-form {
  /deep/.ant-form label {
    font-size: @font-size-large;
  }
  .tip {
    color: @tip-color;
    margin-left: @ec-gutter;
  }
  /deep/.ant-row {
    min-height: unset;
  }
}
.base-tip {
  display: flex;
  align-items: center;
  line-height: 1.2;
  padding-top: 10px;
  padding-bottom: 10px;
  &-icon {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &-text {
    margin-left: 6px !important;
  }
  &.upload {
    padding-top: 0;
    transform: translateY(-10px);
  }
}
.benefits-base-form-item {
  &.upload {
    margin-bottom: 0;

    /deep/.ant-upload-list-picture-card .ant-upload-list-item {
      width: 234px;
      height: 102px;
    }
  }
}
.benefits-base-show {
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
  }
  &-label {
    text-align: right;
    flex-basis: 188px;
    flex-shrink: 0;
    flex-grow: 0;

    &::after {
      content: ": ";
    }
  }
  &-value {
    flex: 1;
    padding: 0 10px;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-btn {}
  .benefits-base-logo {
    width: 234px;
    height: 102px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed #e2e8ee;
  }
}
</style>
