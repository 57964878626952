<template>
  <div>
    <a-modal
      :visible="visible"
      :title="$t('welfarePlat.autoManualRelease.title')"
      @cancel="onCancel"
      @ok="onOk"
      :width="834"
      :maskClosable="false"
      class="auto-manual-modal"
    >
      <div class="auto-manual-modal-top">
        <a-button type="primary" @click="onAdd">
          {{ $t("welfarePlat.autoManualRelease.add") }}
        </a-button>
      </div>
      <ECTable
        :columns="columns"
        :data-source="list"
        :loading="loading"
        :pagination="{
          total,
          current: pageNum,
          pageSize,
          pageSizeOptions: ['5', '10', '15', '20'],
        }"
        key="id"
        rowKey="id"
        @change="onTableChange"
        :scroll="{ x: true }"
      >
        <template slot="state" slot-scope="text, record">
          <a-switch readonly :checked="record.state" />
        </template>
        <template slot="isAuto" slot-scope="text, record">
          <a-switch readonly :checked="record.isAuto" />
        </template>
        <template slot="isSysBenefits" slot-scope="text, record">
          <a-switch readonly :checked="record.isSysBenefits" />
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="text, record">
          <div class="table-operations">
            <a-button type="link" class="table-btn" @click="onEdit(record)">
              <a-icon type="edit" />
              {{ $t("button.edit") }}
            </a-button>
            <a-popconfirm
              :title="$t('button.confirmDelete')"
              :ok-text="$t('button.yes')"
              :cancel-text="$t('button.no')"
              @confirm="onDel(record)"
            >
              <a-button type="link" class="table-btn danger">
                <a-icon type="delete" />
                {{ $t("button.remove") }}
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ECTable>
    </a-modal>

    <CompanyWelfareFormModal
      :visible.sync="formVisible"
      @reload="getList"
      :data="editForm"
      :show="COMPANY_WELFARE_SHOW.AUTO"
    />
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  delBenefits,
  pageBenefits
} from "@/pages/workspace/welfarePlatform/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import CompanyWelfareModal from "./CompanyWelfare/Modal.vue";
import { listPointsShowFormatter, pointsShowFormatter } from "@/utils/tools";
import {
  COMPANY_WELFARE_SHOW
} from "@/pages/home/components/welfare/CompanyWelfare/config";
import CompanyWelfareFormModal
  from "@/pages/home/components/welfare/CompanyWelfare/FormModal.vue";

export default {
  name: "AutomaticReleaseModal",
  computed: {
    COMPANY_WELFARE_SHOW() {
      return COMPANY_WELFARE_SHOW;
    }
  },
  components: { CompanyWelfareFormModal, ECTable },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col2"),
          dataIndex: "name",
          ellipsis: true,
          width: 150,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.benefitsType.label"),
          dataIndex: "benefitsTypeName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col3", { name: this.$store.state.user.platformConf.moneyName }),
          dataIndex: "pointsShow",
          ellipsis: true,
          width: 120,
          align: "right",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.companyId.label"),
          dataIndex: "companyName",
          ellipsis: true,
          width: 140,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.grantObject.label"),
          dataIndex: "grantObjectName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col4"),
          dataIndex: "grantUserCount",
          ellipsis: true,
          width: 100,
          align: "right",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.grantTime.label"),
          dataIndex: "grantTime",
          ellipsis: true,
          width: 160,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col6"),
          dataIndex: "state",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.isAuto.label"),
          dataIndex: "isAuto",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "isAuto" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.isSysBenefits.label"),
          dataIndex: "isSysBenefits",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "isSysBenefits" },
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col5"),
          dataIndex: "createTime",
          ellipsis: true,
          width: 160,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.remark.label"),
          dataIndex: "remark",
          ellipsis: true,
          width: 180,
          align: "left",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      pageNum: 1,
      pageSize: 5,
      formVisible: false,
      editForm: {},
    };
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    onOk() {
      this.onCancel();
    },
    onAdd() {
      // 默认当前登录公司
      this.editForm = {
        companyId: this.$store.state.user.info.currentCompany.companyId,
        isAuto: true,
      };
      this.openForm();
    },
    onEdit(record) {
      this.editForm = {
        ...record,
        points: record.points ? pointsShowFormatter(record.points, true) : 0,
      };
      this.openForm();
    },
    async onDel(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delBenefits({ id: record.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.delete"),
      });
      // 刷新列表
      this.getList();
      this.loading = false;
    },
    onTableChange(page) {
      this.pageNum = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    openForm() {
      this.formVisible = true;
    },
    async getList() {
      this.loading = true;
      const res = await pageBenefits({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyId: this.$store.state.user.info.currentCompany.companyId,
        isAuto: true,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data.records || [];
      this.list = listPointsShowFormatter(list, ["points"]);
      this.total = res.data.total;
      this.pageNum = res.data.current;
      this.pageSize = res.data.size;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
}
</script>

<style lang="less" scoped>
.auto-manual-modal {
  &-top {
    margin-bottom: 20px;
  }
}
</style>
