<!-- 周活跃度 -->
<template>
  <div class="card-shield">
    <CardHeader
      :title="$t('welfarePlat.index.weekActive')"
      :icon="'icon-annular'"
    />
      <Echarts
          :width="echarts.width"
          :height="echarts.height"
          :id="echarts.id"
          :options="echarts.options"
      ></Echarts>
  </div>
</template>
<script>
import Echarts from "@/components/Echarts";
import CardHeader from "@/pages/workspace/backgroundCheck/index/CardHeader.vue";
import Vue from "vue";
export default {
  name: "CardShield",
  components: {
    Echarts,
    CardHeader,
  },
  props: {
    num: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      echarts: {
        height: "300px",
        id: "weekActive",
        options: {
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `${params.marker}${params.name}: ${params.value}%`;
            },
            borderColor: "transparent",
          },
          color: ["#37C1CE", "#266FE8"],
          legend: {
            top: "bottom",
            icon: "circle",
          },
          label: {
            formatter: (params) => {
              return `{${params.color.substr(-2)}|}{padding|${params.name}: ${
                params.value
              }%}`;
            },
            rich: {
              CE: {
                height: 10,
                width: 10,
                borderRadius: 5,
                backgroundColor: "#37C1CE",
              },
              E8: {
                height: 10,
                width: 10,
                borderRadius: 5,
                backgroundColor: "#266FE8",
              },
              padding: {
                padding: 6,
              },
            },
          },
          series: [
            {
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              center: ["50%", "45%"],
              data: [
                {
                  name: this.$t('welfarePlat.index.logined'),
                  value: 0
                },
                {
                  name: this.$t('welfarePlat.index.unlogin'),
                  value: 0
                }
              ],
              itemStyle: {
                borderColor: "#fff",
                borderWidth: 4,
                borderRadius: 8,
              },
              startAngle:180, //起始角度
            },
          ],
        },
      }
    };
  },
  mounted() {
    this.echarts.options.series[0].data[0].value = this.num.active
    this.echarts.options.series[0].data[1].value = this.num.unActive
  }
};
</script>
<style lang="less" scoped>
.card-shield {
  overflow: hidden;
}
</style>
