<!-- 员工数 -->
<template>
  <div class="card-shield">
    <CardHeader
      :title="$t('welfarePlat.index.memberCount')"
      :icon="'icon-circle'"
    />
    <Echarts
      :width="echarts.width"
      :height="echarts.height"
      :id="echarts.id"
      :options="echarts.options"
    ></Echarts>
  </div>
</template>
<script>
import Echarts from "@/components/Echarts";
import CardHeader from "@/pages/workspace/backgroundCheck/index/CardHeader.vue";
import Vue from "vue";
export default {
  name: "CardShield",
  props: {
    num: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Echarts,
    CardHeader,
  },
  data() {
    return {
      echarts: {
        height: "280px",
        id: "memberCount",
        options: {
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `${params.marker}${params.name}: ${params.value}%`;
            },
            borderColor: "transparent",
          },
          color: ["#266FE8", "#37C1CE"],
          legend: {
            top: "bottom",
            icon: "circle",
          },
          label: {
            formatter: (params) => {
              return `{${params.color.substr(-2)}|}{padding|${params.name}: ${
                params.value
              }%}`;
            },
            rich: {
              CE: {
                height: 10,
                width: 10,
                borderRadius: 5,
                backgroundColor: "#37C1CE",
              },
              E8: {
                height: 10,
                width: 10,
                borderRadius: 5,
                backgroundColor: "#266FE8",
              },
              padding: {
                padding: 6,
              },
            },
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              avoidLabelOverlap: false,
              center: ["50%", "45%"],
              data: [
                { value: 0, name: this.$t('welfarePlat.index.active') },
                { value: 0, name: this.$t('welfarePlat.index.unActive') },
              ],
              itemStyle: {
                borderColor: "#fff",
                borderWidth: 4,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.echarts.options.series[0].data[0].value = this.num.login
    this.echarts.options.series[0].data[1].value = this.num.unlogin
  }
};
</script>
<style lang="less" scoped>
.card-shield {
  overflow: hidden;
}
</style>
