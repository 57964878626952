<template>
  <a-modal
    width="800px"
    :title="$t('welfarePlat.companyBenefits.title')"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="company-welfare-modal"
    :maskClosable="false"
  >
    <CompanyWelfare
      @reload="onReload"
      :show="show"
      :sysBenefits="sysBenefits"
      :state="state"
      :companyId="companyId"
    />
  </a-modal>
</template>

<script>
import CompanyWelfare
  from "@/pages/home/components/welfare/CompanyWelfare/Index.vue";
import {
  COMPANY_WELFARE_SHOW
} from "@/pages/home/components/welfare/CompanyWelfare/config";

export default {
  name: "CompanyWelfareModal",
  components: { CompanyWelfare },
  props: {
    visible: Boolean,
    show: {
      type: Number,
      default: COMPANY_WELFARE_SHOW.ALL,
    },
    sysBenefits: {
      type: Boolean,
      default: undefined,
    },
    state: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
      // 尝试刷新外部的列表
      this.onReload();
    },
    onOk() {
      this.onCancel();
    },
    onReload() {
      this.$emit("reload");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.company-welfare-modal {
  /deep/.ant-modal-body {
    padding: 30px;
  }
  &-body {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: @ec-gutter20;
      &-index {
        font-size: @font-size-large;
        color: @text-color;
      }
      &-input {
        margin-left: 24px;
      }
      &-btn {
        color: @error-color;
      }
    }
  }
}
</style>
