<template>
  <ECTable
    :columns="columns"
    :data-source="list"
    :showTitle="isTitle"
    :loading="loading"
    :pagination="isPagination ? { total, current: pageNum, pageSize } : false"
    @change="onTableChange"
    :title="$t('welfarePlat.index.accountRecords.title')"
  >
    <template slot="serviceChargePoints" slot-scope="text">
      {{ +text === 0 ? "--" : text }}
    </template>
    <template slot="tableHeaderRight" v-if="isMore">
      <a-button type="link" @click="onMore">{{ $t("index.more") }}</a-button>
    </template>
  </ECTable>
</template>

<script>
import BigNumber from "bignumber.js";
import { FETCH_CODE, PRICE_DECIMALS, PRICE_RATE_FRONT } from "@/config";
import ECTable from "@/components/ECTable";
import { pagePointsBill } from "@/pages/workspace/welfarePlatform/api";
import { showMsg } from "@/utils";
import {
  listDicFormatter,
  listPointsShowFormatter,
  pointsShowFormatter
} from "@/utils/tools";

export default {
  name: "AccountRecords",
  components: { ECTable },
  props: {
    isTitle: Boolean,
    isPagination: Boolean,
    isMore: Boolean
  },
  data() {
    return {
      list: [],
      columns: [
        {
          title: this.$t("welfarePlat.index.accountRecords.col1"),
          dataIndex: "createTime",
          ellipsis: true,
          width: 160,
          align: "center"
        },
        {
          title: this.$t("welfarePlat.index.accountRecords.col2"),
          dataIndex: "purposeName",
          ellipsis: true,
          width: 140,
          align: "center"
        },
        {
          title: this.$t("welfarePlat.index.accountRecords.col3"),
          dataIndex: "userName",
          ellipsis: true,
          width: 140,
          align: "left"
        },
        {
          title: this.$t("welfarePlat.index.accountRecords.col4"),
          dataIndex: "pointsShow",
          ellipsis: true,
          width: 140,
          align: "right"
        },
        {
          title: this.$t("welfarePlat.index.accountRecords.col6"),
          dataIndex: "serviceChargePointsShow",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "serviceChargePoints" },
          align: "right"
        },
        {
          title: this.$t("welfarePlat.index.accountRecords.col5"),
          dataIndex: "afterPointsShow",
          ellipsis: true,
          width: 160,
          align: "right"
        }
      ],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await pagePointsBill({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = listPointsShowFormatter(res.data.records || [], [
        "points",
        "afterPoints",
        "serviceChargePoints"
      ]);
      this.list = await listDicFormatter(list, [
        { code: "purpose", key: "purpose" }
      ]);
      this.pageSize = res.data.size;
      this.pageNum = res.data.current;
      this.total = res.data.total;
      this.loading = false;
    },
    onTableChange(page) {
      this.pageNum = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onMore() {
      this.$emit("more");
    }
  },
  mounted() {
    this.getList();
  }
};
</script>

<style lang="less" scoped></style>
