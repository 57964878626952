<template>
  <div class="company-welfare">
    <ECTable
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{
            total,
            current: pageNum,
            pageSize,
            pageSizeOptions: ['5', '10', '15', '20'],
          }"
      @change="onTableChange"
      :scroll="{ x: true }"
    >
      <template slot="state" slot-scope="text, record">
        <a-switch readonly :checked="record.state" />
      </template>
      <template slot="isAuto" slot-scope="text, record">
        <a-switch readonly :checked="record.isAuto" />
      </template>
      <template slot="isSysBenefits" slot-scope="text, record">
        <a-switch readonly :checked="record.isSysBenefits" />
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <div class="modal-btn">
      <a-button
        @click="onAdd"
        size="large"
        type="primary"
        ghost
        icon="plus-circle"
        block
      >
        {{ $t("button.newAdd") }}
      </a-button>
    </div>
    <CompanyWelfareFormModal
      :visible.sync="visible"
      @reload="onReload"
      :data="editForm"
      :show="show"
      :companyId="companyId"
    />
  </div>
</template>

<script>
import {
  delBenefits,
  pageBenefits
} from "@/pages/workspace/welfarePlatform/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import ECTable from "@/components/ECTable";
import { listPointsShowFormatter, pointsShowFormatter } from "@/utils/tools";
import CompanyWelfareFormModal from "@/pages/home/components/welfare/CompanyWelfare/FormModal.vue";
import {
  COMPANY_WELFARE_SHOW
} from "@/pages/home/components/welfare/CompanyWelfare/config";

export default {
  name: "CompanyWelfare",
  components: { CompanyWelfareFormModal, ECTable },
  props: {
    show: {
      type: Number,
      default: COMPANY_WELFARE_SHOW.ALL,
    },
    sysBenefits: {
      type: Boolean,
      default: undefined,
    },
    state: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      list: [],
      columns: [
        {
          title: this.$t("welfarePlat.companyBenefits.name.label"),
          dataIndex: "name",
          ellipsis: true,
          width: 140,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.benefitsType.label"),
          dataIndex: "benefitsTypeName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.companyId.label"),
          dataIndex: "companyName",
          ellipsis: true,
          width: 140,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.grantObject.label"),
          dataIndex: "grantObjectName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col3", { name: this.$store.state.user.platformConf.moneyName }),
          dataIndex: "pointsShow",
          ellipsis: true,
          width: 120,
          align: "right",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.grantTime.label"),
          dataIndex: "grantTime",
          ellipsis: true,
          width: 160,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.state.label"),
          dataIndex: "state",
          ellipsis: true,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.isAuto.label"),
          dataIndex: "isAuto",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "isAuto" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.isSysBenefits.label"),
          dataIndex: "isSysBenefits",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "isSysBenefits" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.remark.label"),
          dataIndex: "remark",
          ellipsis: true,
          width: 180,
          align: "left",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
          fixed: "right",
        },
      ],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      loading: false,
      editForm: {},
      visible: false,
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyId: this.companyId ? this.companyId : this.$store.state.user.info.currentCompany.companyId,
        state: this.state === void 0 ? true : this.state,
        isSysBenefits: this.sysBenefits,
      };
      if (this.show === COMPANY_WELFARE_SHOW.AUTO) {
        params.isAuto = true;
      }
      if (this.show === COMPANY_WELFARE_SHOW.MANUAL) {
        params.isAuto = false;
      }
      const res = await pageBenefits(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data.records || [];
      this.list = listPointsShowFormatter(list, ["points"]);
      this.pageNum = res.data.current;
      this.pageSize = res.data.size;
      this.total = res.data.total;
      this.loading = false;
    },
    onTableChange(page) {
      this.pageNum = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      // 默认当前登录公司
      const editForm = {
        companyId: this.$store.state.user.info.currentCompany.companyId,
      };
      if (this.show === COMPANY_WELFARE_SHOW.AUTO) {
        editForm.isAuto = true;
      }
      if (this.show === COMPANY_WELFARE_SHOW.MANUAL) {
        editForm.isAuto = false;
      }
      this.editForm = editForm;
      this.onOpen();
    },
    onEdit(record) {
      this.editForm = {
        ...record,
        points: record.points ? pointsShowFormatter(record.points, true) : 0,
      };
      this.onOpen();
    },
    onOpen() {
      this.visible = true;
    },
    async onDelete(record) {
      if (this.pending) return;
      this.pending = true;
      const res = await delBenefits({ id: record.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.delete"),
      });
      // 刷新列表
      this.onReload();
      this.pending = false;
    },
    onReload() {
      this.getList();
      this.$emit("reload");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped></style>
