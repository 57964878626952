<template>
  <a-modal
    width="904px"
    :visible="visible"
    @cancel="onCancel"
    class="account-records-modal"
    :maskClosable="false"
    destroyOnClose
    :title="$t('welfarePlat.index.accountRecords.title')"
    :footer="null"
  >
    <AccountRecords
      :is-title="false"
      :is-more="false"
      is-pagination
    />
  </a-modal>
</template>

<script>
import ConsumptionRecords
  from "@/pages/workspace/welfarePlatform/ConsumptionRecords/Index.vue";
import AccountRecords
  from "@/pages/workspace/welfarePlatform/AccountRecords/Index.vue";

export default {
  name: "AccountRecordsModal",
  components: { AccountRecords },
  props: {
    visible: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.onClose();
    },
    onClose() {
      this.$emit("update:visible", false);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
