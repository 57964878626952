<template>
  <div class="welfare-platform">
    <a-row class="welfare-platform-top">
      <!-- 基本设置 -->
      <a-col :span="12" class="col">
        <div class="base-set-wrap">
          <div class="base-title">
            {{ $t("welfarePlat.index.baseSet.title") }}
          </div>
          <BenefitsBase @on-edit="onBenefitsBaseEdit" />
        </div>
      </a-col>
      <!-- 分配福币 -->
      <a-col :span="12" class="col">
        <div class="give-wrap">
          <!-- 左 -->
          <div class="give-grid give-left">
            <div class="can-give give-card">
              <div class="number">{{ pointsBalance }}</div>
              <div class="sub-title base-tip">
                <div class="margin text-center">
                  {{
                    messageFormatter($t("welfarePlat.index.allocationPoints.canAllocation"), platformConf.moneyName)
                  }}
                </div>
                <div class="base-tip-icon icon-tip"></div>
              </div>
              <div class="title" @click="onAllocationPoints">
                {{
                  $t("welfarePlat.index.allocationPoints.title", {
                    name: platformConf.moneyName
                  })
                }}
              </div>
            </div>
            <div class="can-give give-card">
              <div class="number">{{ weekConsumption }}</div>
              <div class="sub-title">
                {{ $t("welfarePlat.index.allocationPoints.consumptionWeek") }}
              </div>
            </div>
          </div>
          <!-- 中 -->
          <div class="give-grid give-center">
            <div class="give-card">
              <div class="number">{{ totalConsumption }}</div>
              <div class="sub-title base-tip">
                <div class="margin text-center">
                  {{
                    $t("welfarePlat.index.allocationPoints.consumptionTotal")
                  }}
                </div>
              </div>
              <div class="title" @click="onConsumptionRecords">
                {{
                  $t("welfarePlat.index.allocationPoints.consumptionRecords")
                }}
              </div>
            </div>
            <div class="give-card" style="display: none;">
              <div class="number">32</div>
              <div class="sub-title base-tip">
                <div class="margin">
                  {{ $t("order.gift.title") }}
                </div>
              </div>
              <div class="title" @click="onGiftGrantRecords">
                {{ $t("order.gift.grantRecord") }}
              </div>
            </div>
          </div>
          <!-- 右 -->
          <div class="give-grid give-right">
            <div
              class="can-give manual-give give-card"
              @click="manualRelease"
            >
              <div class="give-right-icon"></div>
              <div class="give-right-title">
                {{ $t("welfarePlat.index.allocationPoints.allocationHandle") }}
              </div>
            </div>
            <div
              class="auto-give manual-give give-card"
              @click="automaticRelease"
            >
              <div class="give-right-icon"></div>
              <div class="give-right-title">
                {{ $t("welfarePlat.index.allocationPoints.allocationAuto") }}
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 图表 -->
    <a-row>
      <!-- 员工数 -->
      <a-col :span="12" class="col">
        <CardMemberCount class="card" v-if="load" :num="staffNum" />
      </a-col>
      <!-- 周活跃度 -->
      <a-col :span="12" class="col">
        <CardWeekActive class="card" v-if="load" :num="weekActiveNum" />
      </a-col>
    </a-row>
    <AccountRecords
      ref="accountRecordsRef"
      @more="onAccountRecords"
      is-title
      is-more
      :is-pagination="false"
    />
    <AccountRecordsModal :visible.sync="accountRecordsVisible" />
    <!-- 手动发放 modal -->
    <template v-if="isManualModal">
      <ManualReleaseModal
        :isManualModal.sync="isManualModal"
        @reloadRecords="onReloadRecords"
      />
    </template>
    <!-- 自动发放 modal -->
    <template v-if="isAutoManualModal">
      <AutomaticReleaseModal
        :visible.sync="isAutoManualModal"
        @reloadRecords="onReloadRecords"
      />
    </template>
    <!--#region 基础信息设置modal -->
    <a-modal
      :visible="benefitsBaseVisible"
      @cancel="onBenefitsBaseCancel"
      @ok="onBenefitsBaseOk"
      class="benefits-base-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('welfarePlat.index.baseSet.title')"
    >
      <BenefitsBase edit ref="benefitsBaseEdit" />
    </a-modal>
    <!--#endregion -->
    <ConsumptionRecordsModal :visible.sync="consumptionRecordsVisible" />
  </div>
</template>

<script>
import CardMemberCount from "./CardMemberCount.vue";
import CardWeekActive from "./CardWeekActive.vue";
import ManualReleaseModal from "@/pages/home/components/welfare/ManualReleaseModal";
import ECTable from "@/components/ECTable";
import {
  grantPoints,
  getGrantRecords,
  savePlatformConf,
  getCompanyUserCount
} from "@/pages/workspace/welfarePlatform/api";
import { getDic } from "@/api";
import { FETCH_CODE } from "@/config";
import BenefitsBase from "@/pages/workspace/welfarePlatform/BenefitsBase.vue";
import { showMsg } from "@/utils";
import { mapGetters, mapState } from "vuex";
import ConsumptionRecordsModal from "@/pages/workspace/welfarePlatform/ConsumptionRecords/Modal.vue";
import AccountRecords from "@/pages/workspace/welfarePlatform/AccountRecords/Index.vue";
import AccountRecordsModal from "@/pages/workspace/welfarePlatform/AccountRecords/Modal.vue";
import AutomaticReleaseModal from "@/pages/home/components/welfare/AutomaticReleaseModal.vue";
import { getStatistics } from "@/pages/home/api";
import { pointsShowFormatter } from "@/utils/tools";
import BigNumber from "bignumber.js";
import { langMixin } from "@/mixins";

export default {
  name: "WelfarePlatform",
  components: {
    AutomaticReleaseModal,
    AccountRecordsModal,
    AccountRecords,
    ConsumptionRecordsModal,
    BenefitsBase,
    CardMemberCount,
    CardWeekActive,
    // CardHeader,
    ManualReleaseModal
  },
  mixins: [langMixin],
  data() {
    return {
      columns: [],
      isManualModal: false,
      currency: "",
      benefitsBaseVisible: false,
      benefitsBaseLoading: false,
      consumptionRecordsVisible: false,
      accountRecordsVisible: false,
      isAutoManualModal: false,
      total: 0,
      weekActive: 0,
      active: 0,
      load: false,
      weekActiveNum: {
        active: 0,
        unActive: 0
      },
      staffNum: {
        login: 0,
        unlogin: 0
      },
      weekConsumption: "0",
      totalConsumption: "0"
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf
    }),
    ...mapGetters("user", ["pointsBalance"])
  },
  methods: {
    onBenefitsBaseEdit() {
      this.benefitsBaseVisible = true;
    },
    onBenefitsBaseCancel() {
      this.benefitsBaseVisible = false;
    },
    async onBenefitsBaseOk() {
      if (this.benefitsBaseLoading) return;
      this.benefitsBaseLoading = true;
      const valid = await this.$refs.benefitsBaseEdit.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.benefitsBaseLoading = false;
        return;
      }
      const params = { ...valid.data };
      const res = await savePlatformConf(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.benefitsBaseLoading = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.change")
      });
      this.benefitsBaseLoading = false;
      this.onBenefitsBaseCancel();
      this.$store.dispatch("user/getUserInfo");
    },
    // 手动发放
    manualRelease() {
      this.isManualModal = true;
    },
    // 自动发放
    automaticRelease() {
      this.isAutoManualModal = true;
    },
    async getCurrency() {
      const res = await getDic({ code: "currency" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      const coinList = res.data || [];
      this.currency =
        coinList.find(i => i.dictCode === "benefits_coin")?.dictCode || "";
    },
    async getData() {
      this.load = false;
      const res = await getCompanyUserCount();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.load = true;
        return;
      }
      this.total = res.data.total || 0;
      this.weekActive = res.data.weekActive || 0;
      this.active = res.data.active || 0;
      this.weekActiveNum = {
        active: BigNumber(this.weekActive / this.total)
          .times(100)
          .toFixed(2),
        unActive: BigNumber((this.total - this.weekActive) / this.total)
          .times(100)
          .toFixed(2)
      };
      this.staffNum = {
        login: BigNumber(this.active / this.total)
          .times(100)
          .toFixed(2),
        unlogin: BigNumber((this.total - this.active) / this.total)
          .times(100)
          .toFixed(2)
      };
      this.load = true;
    },
    // 兑换福币
    onAllocationPoints() {
      this.$router.push("/resource-center");
    },
    // 消费明细
    onConsumptionRecords() {
      this.consumptionRecordsVisible = true;
    },
    // 动账记录
    onAccountRecords() {
      this.accountRecordsVisible = true;
    },
    async getDataList() {
      const res = await getStatistics();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.weekConsumption = pointsShowFormatter(
        res.data.benefitsData?.weekConsumption || 0
      );
      this.totalConsumption = pointsShowFormatter(
        res.data.benefitsData?.totalConsumption || 0
      );
    },
    // 刷新动账记录
    onReloadRecords() {
      this.$refs.accountRecordsRef.getList();
    },
    // 员工入职礼发放记录
    onGiftGrantRecords() {
      this.$router.push({ name: "GiftList" });
    }
  },
  mounted() {
    this.getData();
    this.getDataList();
  }
};
</script>

<style lang="less" scoped>
.welfare-platform {
  &-top {
    height: 396px;
  }
  .col {
    padding: 8px;
    height: 100%;
  }
  .margin {
    margin-right: 4px;
  }
  .base-set-wrap {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e9ebfb;
    height: 100%;
    &:hover {
      box-shadow: 0px 4px 6px 1px rgba(2, 122, 255, 0.21);
      border: 1px solid #ccd8f0;
    }
    .base-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  .base-tip {
    display: flex;
    align-items: center;
    &-icon {
      display: inline-block;
    }
    &-text {
      margin-left: 6px !important;
    }
  }
  .give-wrap {
    height: 100%;
    display: flex;
    & > * {
      width: 38%;
      margin-right: 20px;
    }
    .give-card {
      flex: 1;
      border-radius: 8px;
      border: 1px solid #e9ebfb;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pointer();
      & > * {
        margin-bottom: @ec-gutter;
      }
      &:hover {
        box-shadow: 0px 4px 6px 1px rgba(2, 122, 255, 0.21);
        border: 1px solid #ccd8f0;
      }
      .number {
        font-size: 28px;
        font-weight: bold;
      }
      .sub-title {
        font-size: @font-size-large;
        color: @sub-color;
      }
      .title {
        color: @second-color;
      }
    }
    .give-grid {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: nowrap;
      gap: 18px;
    }
    .give-left {
    }
    .give-right {
      width: 24%;
      margin-right: 0;
      min-width: 165px;
      .manual-give {
        background-color: #266fe8;
        border-radius: 8px;
        &:hover {
          box-shadow: 0px 4px 6px 1px rgba(2, 122, 255, 0.21);
        }
        background-image: url("~@/assets/images/dot-card.png");
        background-size: 62px 44px;
        background-position: 92% 8%;
        background-repeat: no-repeat;
      }
      .auto-give {
        background-color: #ffb32b;
      }
      &-title {
        color: #fff;
        font-size: @font-size-large;
      }
      &-icon {
        .background("~@/assets/images/icon-send.png");
        width: 46px;
        height: 46px;
      }
    }
    .give-center {
      background: url("~@/assets/images/welfare_card.png") no-repeat;
      background-size: 150px 269px;
      background-position: bottom right;
    }
  }
  .card {
    border: 1px solid #e9ebfb;
    padding: @ec-gutter * 2;
    border-radius: 8px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  /deep/.ant-input-group .ant-input {
    border-right: none;
  }
  .text-center {
    text-align: center;
  }
}
</style>
