<template>
  <ECTable
    :columns="columns"
    :data-source="list"
    :pagination="{ current, pageSize, total }"
    @change="handleTableChange"
    :showTitle="false"
    :loading="loading"
    :scroll="{ y: 510 }"
  >
    <template slot="serviceChargePoints" slot-scope="text">
      {{ +text === 0 ? "--" : text }}
    </template>
  </ECTable>
</template>

<script>
import BigNumber from "bignumber.js";
import {FETCH_CODE, PRICE_DECIMALS, PRICE_RATE_FRONT} from "@/config";
import ECTable from "@/components/ECTable";
import {pageConsumptionRecord} from "@/pages/workspace/welfarePlatform/api";
import {showMsg} from "@/utils"
import { listPointsShowFormatter } from "@/utils/tools";
import { mapState } from "vuex";
export default {
  name: "ConsumptionRecords",
  components: { ECTable },
  props: {},
  data() {
    return {
      list: [],
      loading: false,
      current: 1,
      total: 0,
      pageSize: 10
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
    }),
    columns() {
      return [
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol1"),
          dataIndex: "productName",
          ellipsis: true,
          width: 300,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol2"),
          dataIndex: "userName",
          ellipsis: false,
          width: 120,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol3", { name: this.platformConf.moneyName }),
          dataIndex: "payPointsShow",
          ellipsis: false,
          width: 140,
          align: "right",
        },
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol6"),
          dataIndex: "serviceChargePointsShow",
          ellipsis: false,
          width: 120,
          scopedSlots: { customRender: "serviceChargePoints" },
          align: "right",
        },
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol4"),
          dataIndex: "stateName",
          ellipsis: false,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.index.allocationPoints.consumptionCol5"),
          dataIndex: "createTime",
          ellipsis: false,
          width: 160,
          align: "center",
        },
      ];
    },
  },
  methods: {
    async getList() {
      this.loading = true
      const res = await pageConsumptionRecord({ pageNum: this.current, pageSize: this.pageSize })
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false
         showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
        return;
      }
      const list = res.data.records || []
      this.list = listPointsShowFormatter(list, ["serviceChargePoints", "payPoints", "payPrice"]);
      this.current = res.data.current
      this.pageSize = res.data.size
      this.total = res.data.total
      this.loading = false
    },
    handleTableChange (page) {
      this.current = page.current
      this.pageSize = page.pageSize
      this.getList()
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped></style>
