<template>
  <div>
    <!-- 头部tab -->
    <div class="manual-header">
        <span
          v-for="tab in tabs"
          :key="tab.key"
          @click="onTab(tab)"
          class="manual-header-item"
          :class="[
            active === tab.key
              ? 'manual-header-item-active'
              : ''
          ]"
        >
          {{ tab.title }}
        </span>
    </div>
    <div class="manual-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelfareTabs",
  props: {
    active: {
      required: true,
    },
    tabs: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {};
  },
  methods: {
    onTab(tab) {
      this.$emit("update:active", tab.key);
      this.$emit("tab", tab);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.manual-header {
  text-align: center;
  font-size: @font-size-large;
  color: @tip-color;
  height: 42px;

  &-item {
    &:first-child {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    padding: @ec-gutter 30px;
    border: 1px solid #ccd8f0;
    .pointer();

    &-active {
      background: #266fe8;
      border: 1px solid #ccd8f0;
      color: #fff;
    }
  }
}
.manual-body {
  margin-top: 24px;
}
</style>
