<!-- 手动发放 -->
<template>
  <div>
    <a-modal
      :visible="isManualModal"
      :title="$t('welfarePlat.manualRelease.title')"
      @cancel="handleCancel"
      @ok="handleOK"
      :width="580"
      :maskClosable="false"
      class="manual-modal"
    >
      <WelfareTabs :tabs="tabs" :active.sync="currentTab" @tab="onTabChange">
        <!--#region 单人发放 -->
        <div v-show="currentTab === 1">
          <a-form-model
            :model="single"
            :rules="singleRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="single"
          >
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.userIds')"
              prop="userId"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                style="position: relative;"
                size="large"
                allow-clear
                v-model="single.userId"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.userIdsPlaceholder')
                "
                @change="onStaffChange"
              >
                <a-select-option
                  :value="i.userId"
                  v-for="i in staffList"
                  :key="i.userId"
                >
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.mobile')"
              prop="mobile"
            >
              <a-input
                size="large"
                v-model="single.mobile"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.mobilePlaceholder')
                "
                disabled
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.currency')"
              prop="currency"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                style="position: relative;"
                size="large"
                allow-clear
                v-model="single.currency"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.currencyPlaceholder')
                "
                disabled
              >
                <a-select-option
                  :value="i.dictCode"
                  v-for="i in currencyList"
                  :key="i.dictCode"
                >
                  {{ i.dictName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.benefitsId')"
              prop="benefitsId"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                style="position: relative;"
                class="short-item-right"
                @change="onTypeChange"
                v-model="single.benefitsId"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.benefitsIdPlaceholder')
                "
                allow-clear
              >
                <a-select-option
                  :value="i.id"
                  v-for="i in welfareList"
                  :key="i.id"
                >
                  {{ i.name }}
                </a-select-option>
              </a-select>
              <a-button type="link" size="large" @click="showTypeModal">
                {{ $t("welfarePlat.manualRelease.editType") }}
              </a-button>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.points')"
              prop="points"
              class="short-item"
            >
              <a-input-number
                v-model="single.points"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.pointsPlaceholder')
                "
                :min="0"
                :precision="2"
              />
              <div class="short-item-bottom">
                <div class="icon-tip"></div>
                <div>
                  {{ $t("welfarePlat.manualRelease.form.canUse") }}
                  {{ canUseCurrencyName }}&nbsp;&nbsp;
                  {{ canUseCurrencyBalance }}
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.payPassword')"
              prop="payPassword"
            >
              <a-input
                size="large"
                v-model="single.payPassword"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.payPasswordPlaceholder')
                "
                type="password"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.remark')"
              prop="remark"
            >
              <a-textarea
                v-model="single.remark"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.remarkPlaceholder')
                "
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <!--#endregion -->
        <!--#region 批量发放 -->
        <div v-show="currentTab === 2">
          <a-form-model
            :model="batch"
            :rules="batchRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="batch"
          >
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.benefitsId')"
              prop="benefitsId"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                style="position: relative;"
                class="short-item-right"
                @change="onTypeChange"
                v-model="batch.benefitsId"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.benefitsIdPlaceholder')
                "
                allow-clear
              >
                <a-select-option
                  :value="i.id"
                  v-for="i in welfareList"
                  :key="i.id"
                >
                  {{ i.name }}
                </a-select-option>
              </a-select>
              <a-button type="link" size="large" @click="showTypeModal">
                {{ $t("welfarePlat.manualRelease.editType") }}
              </a-button>
            </a-form-model-item>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.form.payPassword')"
              prop="payPassword"
            >
              <a-input
                type="password"
                v-model="batch.payPassword"
                :placeholder="
                  $t('welfarePlat.manualRelease.form.payPasswordPlaceholder')
                "
              >
              </a-input>
            </a-form-model-item>
            <div class="batch-header">
              <div class="batch-header-text">
                {{ $t("welfarePlat.manualRelease.batchTips") }}
              </div>
              <a-button
                type="primary"
                ghost
                shape="round"
                size="small"
                @click="download"
              >
                {{ $t("welfarePlat.manualRelease.batchDownload") }}
              </a-button>
            </div>
            <a-form-model-item
              :label="$t('welfarePlat.manualRelease.batchUpload')"
              prop="fileList"
            >
              <EcUpload
                :fileName="downloadFileName"
                formName="file"
                action="/api/company/benefits/grant/template/import"
                :fileList.sync="batch.fileList"
                :params="{
                  payPassword: batch.payPassword,
                  benefitsId: batch.benefitsId
                }"
                :disabled="!batch.payPassword || !batch.benefitsId"
                @change="onFileChange"
                :tips="$t('welfarePlat.manualRelease.form.fileTips')"
                :customRequest="onFileUpload"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <!--#endregion -->
      </WelfareTabs>
    </a-modal>

    <CompanyWelfareModal
      :visible.sync="isShowModal"
      @reload="getWelfare"
      :show="COMPANY_WELFARE_SHOW.MANUAL"
    />
  </div>
</template>

<script>
import CompanyWelfareModal from "./CompanyWelfare/Modal.vue";
import {
  getBenefitTemplate,
  grantPoints,
  pageBenefits
} from "@/pages/workspace/welfarePlatform/api";
import { FETCH_CODE, REG_EMAIL, REG_MOBILE, REG_NAN } from "@/config";
import { pageStaff } from "@/pages/HR/api";
import { mapGetters, mapState } from "vuex";
import { showMsg } from "@/utils";
import { trim } from "lodash";
import { getDic } from "@/api";
import { pointsSaveFormatter, pointsShowFormatter } from "@/utils/tools";
import EcUpload from "@/components/EcUpload.vue";
import moment from "moment/moment";
import WelfareTabs from "@/pages/home/components/welfare/Tabs.vue";
import { COMPANY_WELFARE_SHOW } from "@/pages/home/components/welfare/CompanyWelfare/config";
import { read, utils } from "xlsx";
import ECTable from "@/components/ECTable";
import api from "@/utils/fetch";
import { langMixin } from "@/mixins";

const SingleFormEmpty = {
  userId: undefined,
  currency: "benefits_coin",
  mobile: null,
  points: null,
  benefitsId: undefined,
  payPassword: null,
  remark: null
};

const BatchFormEmpty = {
  payPassword: null,
  fileList: [],
  benefitsId: undefined,
  userList: []
};

export default {
  name: "ManualReleaseModal",
  components: {
    WelfareTabs,
    EcUpload,
    CompanyWelfareModal
  },
  props: {
    isManualModal: Boolean
  },
  mixins: [langMixin],
  computed: {
    COMPANY_WELFARE_SHOW() {
      return COMPANY_WELFARE_SHOW;
    },
    ...mapState({
      platformConf: state => state.user.platformConf
    }),
    ...mapGetters("user", ["coinBalance", "pointsBalance", "surveyBalance"]),
    canUseCurrencyName() {
      if (!this.single.currency) return null;
      const curr = this.currencyList.find(it => {
        return it.dictCode === this.single.currency;
      });
      if (!curr) return null;
      return curr.dictName;
    },
    canUseCurrencyBalance() {
      if (this.single.currency === "benefits_coin") {
        return this.pointsBalance;
      }
      if (this.single.currency === "back_tone_coin") {
        return this.coinBalance;
      }
      if (this.single.currency === "survey_coin") {
        return this.surveyBalance;
      }
      return pointsShowFormatter(0);
    },
    downloadFileName() {
      let name = this.$t("welfarePlat.manualRelease.batchFileName");
      name += moment().format(".YYYYMMDDHHmmss");
      return name + ".xlsx";
    },
    labelCol() {
      return this.calcFormItemLayout([5, 7], [18, 16]).labelCol;
    },
    wrapperCol() {
      return this.calcFormItemLayout([5, 7], [18, 16]).wrapperCol;
    }
  },
  data() {
    return {
      currentTab: 1,
      tabs: [
        { key: 1, title: this.$t("welfarePlat.manualRelease.single") },
        { key: 2, title: this.$t("welfarePlat.manualRelease.batch") }
      ],
      single: {
        ...SingleFormEmpty
      },
      singleRules: {
        userId: [
          {
            required: true,
            message: this.$t("welfarePlat.manualRelease.form.userIdsRequired")
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t("welfarePlat.manualRelease.form.currencyRequired")
          }
        ],
        mobile: [
          {
            required: true,
            message: this.$t("welfarePlat.manualRelease.form.mobileRequired")
          },
          {
            pattern: REG_MOBILE,
            message: this.$t("welfarePlat.manualRelease.form.mobileReg")
          }
        ],
        points: [
          {
            required: true,
            message: this.$t("welfarePlat.manualRelease.form.pointsRequired")
          }
        ],
        benefitsId: [
          {
            required: true,
            message: this.$t(
              "welfarePlat.manualRelease.form.benefitsIdRequired"
            )
          }
        ],
        payPassword: [
          {
            required: true,
            message: this.$t(
              "welfarePlat.manualRelease.form.payPasswordRequired"
            )
          }
        ]
      },
      batch: {
        ...BatchFormEmpty
      },
      batchRules: {
        payPassword: [
          {
            required: true,
            message: this.$t(
              "welfarePlat.manualRelease.form.payPasswordRequired"
            )
          }
        ],
        benefitsId: [
          {
            required: true,
            message: this.$t(
              "welfarePlat.manualRelease.form.benefitsIdRequired"
            )
          }
        ],
      },
      isShowModal: false,
      welfareList: [], // 福利类型
      staffList: [],
      currencyList: [],
      pending: false,
      fileAccept:
        "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      downloadLoading: false
    };
  },
  methods: {
    handleCancel() {
      if (this.currentTab === 1) {
        this.$refs.single.resetFields();
      } else {
        this.$refs.batch.resetFields();
      }
      this.$emit("update:isManualModal", false);
    },
    handleOK() {
      if (this.pending) return;
      this.pending = true;
      // 单人发放
      if (this.currentTab === 1) {
        this.$refs.single.validate(async (valid, errors) => {
          if (!valid) {
            showMsg({ flag: 1, msg: Object.values(errors)[0]?.[0]?.message });
            this.pending = false;
            return;
          }
          const params = {
            benefitsId: this.single.benefitsId,
            currency: this.single.currency,
            payPassword: this.single.payPassword,
            points: pointsSaveFormatter(this.single.points),
            remark: trim(this.single.remark),
            userIds: [this.single.userId]
          };
          const res = await grantPoints(params);
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg({
              flag: FETCH_CODE.ERROR.KEY,
              msg: res.msg
            });
            this.pending = false;
            return;
          }
          showMsg({
            flag: FETCH_CODE.SUCCESS.KEY,
            msg: this.$t("showMsg.success.grant")
          });
          this.pending = false;
          // 重置弹窗
          this.onResetSingle();
          // 刷新余额
          this.$store.dispatch("user/getUserInfo");
          // 刷新动账列表
          this.$emit("reloadRecords");
        });
        return;
      }
      // 批量发放
      this.onResetBatch();
      this.handleCancel();
    },
    onResetSingle() {
      this.single = {
        ...SingleFormEmpty,
        currency: this.single.currency
      };
    },
    onResetBatch() {
      this.batch = {
        ...BatchFormEmpty
      };
    },
    onTabChange(tab) {
      this.currentTab = tab.key;
      if (this.currentTab === 1) {
        this.onResetSingle();
      }
    },
    showTypeModal() {
      this.isShowModal = true;
    },
    async getWelfare() {
      const res = await pageBenefits({
        pageNum: 1,
        pageSize: 9999,
        companyId: this.$store.state.user.info.currentCompany.companyId,
        isAuto: false,
        state: true
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      this.welfareList = res.data?.records || [];
    },
    async getStaff() {
      const res = await pageStaff({
        pageNum: 1,
        pageSize: 9999,
        companyId: this.$store.state.user.info.currentCompany.companyId
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.staffList = res.data?.records || [];
    },
    onTypeChange(value) {
      const curr = this.welfareList.find(it => it.id === value);
      if (!curr) return;
      // 带出维护的金额
      this.single.points = pointsShowFormatter(curr.points, true);
    },
    onStaffChange(userId) {
      this.single.mobile =
        this.staffList.find(i => i.userId === userId)?.mobilePhoneNumber || "";
    },
    async getCurrencyList() {
      const res = await getDic({ code: "currency" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      let currencyList = res.data || [];
      // 只显示福币、调研币、背调币
      currencyList = currencyList.filter(it => {
        return ["benefits_coin", "survey_coin", "back_tone_coin"].includes(
          it.dictCode
        );
      });
      currencyList.forEach(it => {
        if (it.dictCode === "benefits_coin") {
          it.dictName = this.$store.state.user.platformConf.moneyName;
        }
      });
      this.currencyList = currencyList;
      // 默认福币
      if (currencyList.some(it => it.dictCode === "benefits_coin")) {
        this.single.currency = "benefits_coin";
      }
    },
    onFileChange() {
    },
    async download() {
      if (this.downloadLoading) return;
      this.downloadLoading = true;
      const res = await getBenefitTemplate();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.downloadLoading = false;
        return;
      }
      this.downloadLoading = false;
    },
    async onFileUpload(option) {
      const { file, filename, action, data, onError, onSuccess } = option;

      // 前端解析文件 假装上传文件
      const result = { flag: FETCH_CODE.WARNING.KEY, data: [], msg: "" };
      const fileReader = new FileReader();
      const excelColumns = [
        {
          dataIndex: "email",
          title: "员工邮箱",
          width: 160,
          align: "left",
          rules: [
            {
              required: true,
              message: this.$t("welfarePlat.manualRelease.form.emailRequired")
            },
            {
              pattern: REG_EMAIL,
              message: this.$t("welfarePlat.manualRelease.form.emailReg")
            }
          ]
        },
        {
          dataIndex: "name",
          title: "员工名称",
          width: 120,
          align: "left",
          rules: [
            {
              required: true,
              message: this.$t("welfarePlat.manualRelease.form.userIdsRequired")
            }
          ]
        },
        {
          dataIndex: "points",
          title: "发放金额",
          width: 120,
          align: "right",
          rules: [
            {
              required: true,
              message: this.$t("welfarePlat.manualRelease.form.pointsRequired2")
            },
            {
              pattern: REG_NAN,
              message: this.$t("welfarePlat.manualRelease.form.pointsReg")
            }
          ]
        },
        { dataIndex: "remark", title: "说明", width: 150, align: "left" }
      ];
      const handleError = msg => {
        const error = {
          method: "POST",
          status: 0,
          url: action,
          message: msg
        };
        onError(error, result);
        showMsg(result);
      };
      fileReader.onload = ev => {
        try {
          const fileData = ev.target.result;
          const workbook = read(fileData, {
            type: "binary"
          });
          // 取第一张表
          const sheetName = workbook.SheetNames[0];
          // 生成json表格内容
          const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);
          // excel解析成功
          // 判断数据是否为空
          if (!sheetData.length) {
            result.msg = this.$t("showMsg.warning.empty");
            handleError(result.msg);
            return;
          }
          // 数据解析
          const validData = [];
          const errors = {};
          const insertError = (dataIndex, msg, index) => {
            const error = {
              dataIndex,
              message: msg,
              index
            };
            if (errors[dataIndex]) {
              errors[dataIndex].push(error);
              return;
            }
            errors[dataIndex] = [error];
          };
          sheetData.forEach((it, idx) => {
            const row = {};
            excelColumns.forEach(col => {
              row[col.dataIndex] = null;
              // 赋值
              for (const key in it) {
                if (key === col.title) {
                  row[col.dataIndex] = it[key];
                }
              }
              // 校验
              if (col.rules) {
                for (const rule of col.rules) {
                  // 必填
                  if (rule.required) {
                    if (!row[col.dataIndex]) {
                      insertError(col.dataIndex, rule.message, idx);
                    }
                  }
                  // 正则
                  if (rule.pattern) {
                    const _val = row[col.dataIndex] + "";
                    if (!rule.pattern.test(_val)) {
                      insertError(col.dataIndex, rule.message, idx);
                    }
                  }
                }
              }
            });
            validData.push(row);
          });
          // 解析有错误提示
          const errorsList = Object.values(errors);
          if (errorsList.length) {
            result.flag = FETCH_CODE.ERROR.KEY;
            result.msg = this.$t("welfarePlat.manualRelease.readExcelMessage", {
              index: errorsList[0]?.[0].index + 1,
              msg: errorsList[0]?.[0].message
            });
            handleError(result.msg);
            return;
          }
          // 解析完全正确 上传完成 展示数据
          result.flag = FETCH_CODE.SUCCESS.KEY;
          result.data = validData;
          onSuccess(result.data, {});
          // 弹窗展示数据
          this.$confirm({
            width: 650,
            title: this.$t("welfarePlat.manualRelease.readExcelConfirm"),
            content: h => {
              const curr = this.welfareList.find((it) => it.id === this.batch.benefitsId);
              return (
                <div>
                  <p>{curr?.name}</p>
                  <ECTable
                    columns={excelColumns}
                    data-source={result.data}
                    pagination={false}
                  />
                </div>
              );
            },
            okText: this.$t("welfarePlat.manualRelease.readExcelConfirmOk"),
            onOk: async () => {
              // 请求接口发放
              // TODO 暂时用上传接口
              const res = await api.upload(action.replace(/^\/api/, ""), {
                [filename]: file,
                ...data,
              });
              if (res.flag === FETCH_CODE.SUCCESS.KEY) {
                const arr = res.data.data.map(it => {
                  return `${it.userName}${this.$t(
                    "welfarePlat.manualRelease.title"
                  )}${pointsShowFormatter(it.points)}${
                    this.$store.state.user.platformConf.moneyName
                  }${this.$t("showMsg.success.made")}`;
                });
                this.$success({
                  title: this.$t("welfarePlat.manualRelease.title"),
                  // JSX support
                  content: (
                    <div>
                      {arr.map(str => (
                        <p>{str}</p>
                      ))}
                    </div>
                  ),
                  onOk: () => {
                    this.batch.fileList = [];
                    // 刷新余额
                    this.$store.dispatch("user/getUserInfo");
                    // 刷新动账列表
                    this.$emit("reloadRecords");
                  }
                });
                return;
              }
              showMsg(res);
            },
            onCancel: () => {
              // 取消发放
            }
          });
        } catch (err) {
          let msg = this.$t("welfarePlat.manualRelease.readExcelFailed", {
            msg: err.message
          });
          msg += this.$t("showMsg.helper1");
          result.msg = msg;
          // excel解析失败 抛出上传失败
          handleError(result.msg);
        }
      };
      fileReader.readAsBinaryString(file);
    }
  },
  mounted() {
    this.getWelfare();
    this.getStaff();
    this.getCurrencyList();
  }
};
</script>

<style lang="less" scoped>
.manual-modal {
  color: @tip-color;
  /deep/.ant-modal-footer {
    padding-top: 0;
  }
  /deep/.ant-modal-body {
    padding: 0 35px;
  }
  .short-item {
    display: flex;
    flex-wrap: nowrap;
    /deep/.ant-form-item-control-wrapper {
      width: 45%;
    }
    &-bottom {
      display: flex;
      align-items: center;
      font-size: @font-size-large;
      color: @tip-color;
      .icon-tip {
        margin-right: 8px;
      }
    }
  }
  .short-item-right {
    width: 60%;
  }
  .batch-header {
    font-size: @font-size-large;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &-text {
      margin-right: 14px;
    }
  }
}
</style>
