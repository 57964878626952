<template>
  <a-modal
    width="1020px"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="consumption-records-modal"
    :maskClosable="false"
    destroyOnClose
    :title="$t('welfarePlat.index.allocationPoints.consumptionTitle')"
    :okText="$t('button.sexport')"
  >
    <ConsumptionRecords ref="consumptionRecordsRef" />
  </a-modal>
</template>

<script>
import ConsumptionRecords
  from "@/pages/workspace/welfarePlatform/ConsumptionRecords/Index.vue";
import {consumptionRecordExport} from "@/pages/workspace/welfarePlatform/api";
import {mapState} from "vuex";
import {FETCH_CODE} from "@/config";

export default {
  name: "ConsumptionRecordsModal",
  components: { ConsumptionRecords },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapState({
      info: state => state.user.info
    })
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onOk() {
      if (this.loading) return;
      this.loading = true;
      const res = await consumptionRecordExport({ companyId: this.info?.currentCompany.companyId })
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.$message.error(res.msg)
        this.loading = false;
        return
      }
      this.onClose()
      this.loading = false;
    },
    onCancel() {
      this.onClose();
    },
    onClose() {
      this.$emit("update:visible", false);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
